// NEUTRAL
$neutral-white: white;
$neutral-100: #000000;
$neutral-70: #2F2F30;
$neutral-20: #E5E5EA;
$neutral-10: #F2F2F7;
// NEUTRAL

// PRIMARY
$primary-20: #F8F6F1;
$primary-40: #D0E1FB;
$primary-dark: #1769E6;
// PRIMARY

.col-primary{
  color: $primary-dark;
}

.col-grey{
  color: $neutral-70;
}

//FONT FAMILY
.fam-mnt{
  font-family: 'Montserrat' !important;
}

.fam-int{
  font-family: 'Inter' !important;
}

.fam-mul{
  font-family: 'Mulish' !important;
}
//FONT FAMILY

// FONT-SIZES
.font-12 {
  font-size: 0.75rem;
}
.font-14 {
  font-size: 0.875rem;
}
.font-16 {
  font-size: 1rem;
}
.font-18 {
  font-size: 1.125rem;
}
.font-20 {
  font-size: 1.25rem;
}
.font-22 {
  font-size: 1.375rem;
}
.font-24 {
  font-size: 1.5rem;
}
.font-26 {
  font-size: 1.625rem;
}
.font-28 {
  font-size: 1.75rem;
}
.font-30 {
  font-size: 1.875rem;
}
.font-31{
  font-size: 2.125rem;
}
.font-32{
  font-size: 2.5rem;
}
.font-33{
  font-size: 2.25rem;
}
.font-36{
  font-size: 3.125rem;
}
.font-38{
  font-size: 3.5rem;
}
.font-39{
  font-size: 3.75rem;
}
.font-40 {
  font-size: 4.375rem;
}
.font-42{
  font-size: 6.25rem; 
}
.font-50{
  font-size: 15.625rem;
}
.wg-1 { font-weight: 100; }
.wg-2 { font-weight: 200; }
.wg-3 { font-weight: 300; }
.wg-4 { font-weight: 400; }
.wg-5 { font-weight: 500; }
.wg-6 { font-weight: 600; }
.wg-7 { font-weight: 700; }
.wg-8 { font-weight: 800; }
.wg-9 { font-weight: 900; }
// FONT-SIZES

// COMPONENTS
.btn-primary {
  display: inline-flex;
  padding: 0.75rem 1.875rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  background-color: $primary-dark;
  border-radius: 3.125rem;
  border: none;
  color: $neutral-white;
  font-size: 1.25rem;
  font-family: 'Mulish';
  font-weight: 700;
  cursor: pointer;
  a{
    text-decoration: none !important;
    color: $neutral-white;
  }
}

button {
  cursor: pointer;
}
// COMPONENTS