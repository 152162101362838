.navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem 1.5rem;
  z-index: 999;
  height: 100%;
  max-height: 6rem;
  border-bottom: 1px solid rgba(47, 47, 48, 0.10);
  .mob-nav-logo{
    display: inline;
    img{
      max-width: 12rem;
    }
  }
  .main-links>.active{
    font-weight: 700;
  }
}

.nav-ham {
  svg > path {
    stroke: $neutral-100;
  }
  align-self: center;
  padding-left: 1rem;
}

.nav-btn{
  align-self: center;
  align-items: center;
  display: flex;
}
.open-nav{
  padding: 1.5rem;
  justify-content: flex-start !important;
  .nav-bottom{
    flex-direction: column !important;
    gap: 1rem;
    padding-top: 1rem;
  }
  .nav-top{
    .routes{
      align-items: center;
      border-bottom: 1px solid rgba(47, 47, 48, 0.10);
      padding-bottom: 1rem;
      gap: 1rem;
    }
  }
}

.navigation-wrapper {
  width: 100%;
  height: 100vh;
  z-index: 999;
  position: fixed;
  top: 0;
  background-color: $primary-20;
  left: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  transform: translate(100%, 0);
  transition: transform 0.2s ease 0s;

  
  #logo {
    max-width: 11rem;
    display: none;
  
    img {
      width: 100%;
      height: auto;
    }
  }

  .nav-top,
  .nav-bottom {
    width: 100%;
  }

  .nav-bottom {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;
    .blog-btn{
      background-color: transparent;
      color: $primary-dark;
      border: 1px solid $primary-dark;
    }
    button {
      a {
        text-decoration: none;
        width: 100%;
        height: 100%;
        display: block;
        color: $neutral-white;
      }
    }
    a {
      font-size: 1.05rem;
      color: inherit;
      text-decoration: none;
    }
  }

  .mob-nav-logo {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid $neutral-20;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: $neutral-70;
      font-size: 37px;
    }
  }
  .routes {
    list-style-type: none;
    padding-top: 1rem;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    font-size: 1.5rem;
    .main-links {
      padding: 0.5rem 0.2rem;
      height: auto !important;
      position: relative;
      
      .nav-links {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: 'Inter';
        color: $neutral-70!important;
        
      }
      &:nth-child(2) {
        p {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
      }
    }
  }
}

.nav-links{
  .btn-primary{
    background: transparent;
    border: 2px solid $primary-dark;
    color: $primary-dark;
  }
}
.nav-white{
  .navigation-wrapper{
    .routes{
      display: none !important;
    }
    .defend{
      display: none !important;
    }
    .back{
      justify-content: end !important;
    }
  }
}

.nav-black{
  .navigation-wrapper{
    .back{
      display: none !important;
    }
  }
}