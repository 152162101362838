@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import "./sass/vars";
@import "./sass/header";
@import "./sass/breakpoint";
@import "./sass/landing";
@import "./sass/footer";
@import "./sass/familyOffice.scss";
@import "./sass/institution.scss";
@import "./sass/individual.scss";
@import "./sass/inflation.scss";
@import "./sass/contactUs.scss";


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: $primary-20;
}

html {
  scroll-behavior: smooth;
}
a{
  text-decoration: none !important;
}

#root {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
}

.open-nav {
  transform: translateX(0%) !important;
}