.inflation-page{
    width: 100%;
}
.inflation-guy-hero{
    margin-top: -6rem;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    h2{
        color: $neutral-white;
        padding-bottom: 5rem;
        text-align: center;
        position: absolute;
    }
    img{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .mob-img{
        display: none;
    }
    .desktop-img{
        display: block;
    }
}

.inflation-guy{
    padding-block: 7rem;
    .inflation-info{
        display: flex;
        flex-direction: column;
        width: 70%;
        align-items: center;
        gap: 2rem;
        margin: auto;
        text-align: center;
        p{
            color: rgba(47, 47, 48, 0.80);
        }
        .sprinkle1{
            position: absolute;
            align-self: flex-end;
            margin-right: -4rem;
            margin-top: -3rem;
        }
    }
    .inflationMoney{
        position: absolute;
        margin-top: 9rem;
    }
}

.heard{
    background: $neutral-70;
    color: $neutral-white;
    .slider{
        .how-carousal{
            animation: scroll $animationSpeed linear infinite;
            display: flex;
        }
    }
    .heard-card{
        min-height: 15vh;
        margin-inline: 5rem;
        display: flex;
        align-items: center;
    }
    .mob-heard-card{
        display: none;
    }
}

.animationBook{
    position: relative;
    width: 100%;
    .animateBook:nth-child(2){
        // .animateBook{
            flex-direction: row-reverse;
            gap: 2%;
            .animateBookLeft{
                width: 35%;
                align-items: flex-start;
            }
            .animateBookRight{
                align-items: flex-end;
            }
        // }
    }
    .animateBook{
        width: 100%;
        padding-block: 5rem;
        display: flex;
        flex-direction: row;
        padding-inline: 10rem;
        scroll-snap-type: x mandatory;
        gap: 7%;
        .animateBookLeft{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 33%;
            align-items: flex-end;
            .book-img{
                // width: 75%;
                img{
                    width: 100%;
                }
            }
        }
        .animateBookRight{
            display: flex;
            flex-direction: column;
            justify-content: center;
            // padding-inline: 4rem 0rem;
            width: 60%;
            .book-text{
                color: rgba(47, 47, 48, 0.80);
                display: flex;
                // width: 70%;
                gap: 2rem;
                flex-direction: column;
            }
        }
    }
}

.mobAnimationBook{
    display: none;
}

.reviews{
    padding: 4rem 7rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    .review-row{
        gap: 6%;
        display: flex;
        width: 100%;
        .review-card{
            width: 43%;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            border-radius: 1.25rem;
            box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.05);
            .star{
                display: flex;
                gap: 0.5rem;
            }
            p{
                color: rgba(47, 47, 48, 0.80);
                min-height: 6rem;
            }
        }
    }
    .ml{
        margin-left: 15%;
    }
}

.podcasts{
    display: flex;
    flex-direction: column;
    padding: 6rem 7rem;
    width: 100%;
    align-items: center;
    background: #2F2F30;
    color: $neutral-white;
    gap: 4rem;
    .btn-primary{
        border: 1px solid #FFF;
        background: transparent;
    }
    .head{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .podcast-body{
        display: flex;
        width: 100%;
        gap: 6%;
        .podcast-img{
            width: 32%;
            img{
                width: 100%;
            }
        }
        .podcast-text{
            display: flex;
            flex-direction: column;
            width: 62%;
            .button-bottom{
                display: none;
            }
            .podcast-row{
                display: flex;
                gap: 1rem;
                padding-bottom: 1.5rem;
                padding-top: 1.5rem;
                border-bottom: 1px solid rgba(255, 255, 255, 0.20);
                .podcast-info{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                }
            }
            .podcast-row:not(:first-child){
                padding-top: 1.5rem;
            }
        }
    }
}

.photos{
    padding: 6rem 7rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3.5rem;
    img{
        width: 100%;
        height: 100%;
        border-radius: 1.25rem;
    }
    .photo-row{
        display: flex;
        gap: 4%;
        width: 100%;
        margin: auto;
        justify-content: space-between;
        .p1{
            width: 58%;
        }
        .p2{
            width: 38%;
        }
        .p3{
            width: 30%;
        }
        .p4{
            width: 30%;
        }
        .p5{
            width: 30%;
        }
        .p6{
            width: 100%;
        }
    }
}

.mob-photos{
    display: none;
}

.spotify{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FACC71 100%);
    display: flex;
    padding: 6rem 7rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    .spotify-img{
        img{
            width: 100%;
        }
    }
    .spotify-info{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        p{
            color: rgba(47, 47, 48, 0.80);
        }
        .spotify-icon{
            display: flex;
            gap: 6%;
            img{
                width: 45%;
            }
        }
    }
}