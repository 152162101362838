    .landing{
        padding-block: 5rem;
        .mob-hero{
            display: none;
        }
        .border{
            position: relative;
            z-index: 2;
            color: $primary-20;
            text-shadow: -2px -2px 0 $primary-dark, 2px -2px 0 $primary-dark, -2px 2px 0 $primary-dark, 2px 2px 0 $primary-dark;
        }
        .pd{
            padding-left: 10rem;
        }
        p{
            font-size: 9.375rem;
            font-weight: 800;
            line-height: 8.5rem;
        }
        div {
            padding-left: 15rem;
            position: relative;
            .fill{
                color: $primary-dark;
                position: relative;
                z-index: 2;
            }
            img{
                position: absolute;
                bottom: -115px;
                right: -150px;
                z-index: 0;
            }
        }
        .btn{
            padding-top: 2rem;
            .btn-primary{
                padding: 0.7rem 2rem;
                background: transparent;
                border: 2px solid $primary-dark;
                color: $primary-dark;
                flex-direction: row;
                font-size: 1.875rem;
                svg{
                    padding-bottom: 3px;
                }
            }
        }
    }

    .why{
        width: 100%;
        background: $primary-40;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-items: center;
        padding-block: 4rem;

        .why-img{
            position: relative;
            text-align: end;
            .sprinkle1{
                position: absolute;
                left: 0;
                bottom: 0;
                margin: 2rem;
            }
            .why-img1{
                width: 90%;
                border-radius: 1.25rem;
            }
        }
        .why-text{
            width: 100%;
            display: contents;
            position: relative;
            .why-card{
                background: $neutral-white;
                border: 1px solid $neutral-70;
                border-radius: 1.25rem;
                padding: 2rem 1.875rem;
                position: relative;
                left: -8vw;
                .why-title{
                    color: $primary-dark;
                    padding-bottom: 1rem;
                }
                h2{
                    color: $neutral-70;
                    padding-bottom: 1rem;
                }
                p{
                    padding-bottom: 1rem;
                    color: rgba(47, 47, 48, 0.80);
                }
                .search{
                    width: 8%;
                    position: absolute;
                    top: -20px;
                    right: -15px;
                }
            }
            .sprinkle1{
                right: 2vw;
                position: absolute;
                align-self: baseline;
            }
        }
    }

    .benefits{
        // height: 80vh;
        padding-block: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .benefits-text{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 55%;
            gap: 2.5rem;
            text-align: center;
            color: $neutral-70;
            p{
                color: rgba(47, 47, 48, 0.80);
            }
            .btn{
                .btn-primary{
                    padding: 0.7rem 2rem;
                    background: transparent;
                    border: 2px solid $neutral-70;
                    color: $neutral-70;
                    flex-direction: row;
                    font-size: 1.875rem;
                    svg{
                        padding-bottom: 3px;
                    }
                }
            }
        }
        .benefits-img{
            position: absolute;
            left: 0;
            margin-bottom: -5rem;
            align-self: end;
            img{
                width: 120%;
            }
        }
        .sprinkle1{
            align-self: baseline;
            position: absolute;
            right: 8vw;
        }
        .sprinkle2{
            position: absolute;
            align-self: baseline;
            left: 25vw;
        }
        .sprinkle3{
            position: absolute;
            align-self: flex-end;
            right: 20vw;
        }
        .mob-benefits-img{
            display: none;
        }
    }

    $animationSpeed: 40s;
    // Animation
    @keyframes scroll {
        0% { transform: translateX(0); }
        100% { transform: translateX(calc(-250px * 7))}
    }
    .slider{
        overflow:hidden;
        position: relative;
        &::after {
            right: 0;
            top: 0;
            transform: rotateZ(180deg);
        }
        &::before {
            left: 0;
            top: 0;
        }
        .how-carousel {
            animation: scroll $animationSpeed linear infinite;
            display: flex;
            background: #FEFEFE;
            .how-card{
                margin-right: 2.5rem;
                min-width: 30vh;
            }
        }
    }

    .how{
        background: #FEFEFE;
        text-align: center;
        padding-block: 8rem;
        width: 100%;
        .how-title{
            padding-bottom: 5rem;
        }
        .mob-cards{
            display: none;
        }
        .how-card{
            text-align: center;
            padding: 2rem 1rem;
            border-radius: 1.25rem;
            border: 2px solid #2F2F30;
            background: #F8F6F1;
            box-shadow: 5px 5px 50px 0px rgba(47, 47, 48, 0.05);
            .how-card-title{
                text-wrap: wrap;
                min-height: 3.8rem;
            }
            .how-img{
                padding-top: 2rem;
                img{
                    width: 65%;
                }
            }
        }
    }

    .about{
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FACC71 100%);
        display: flex;
        min-height: 90vh;
        align-items: center;
        width: 100%;
        padding-top: 8rem;
        justify-content: center;
        .about-card{
            border-radius: 1.875rem;
            border: 1px solid #2F2F30;
            background: #D0E1FB;
            display: flex;
            gap: 1.5rem;
            padding: 4rem 3rem;
            width: 60%;
            margin-bottom: 3rem;
            .about-info{
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                color: $neutral-70;
            }
            .about-detail{
                color: rgba(47, 47, 48, 0.80);
            }
            .btn-primary{
                color: $neutral-70;
                background: $neutral-white;
            }
            .inflationGuy{
                width:  23%;
                position: absolute;
                margin-top: -12%;
                margin-left: 30%;
            }
        }
        .sprinkle1{
            position: absolute;
            align-self: baseline;
            margin-inline-start: -75vw;
        }
        .dollar{
            right: 0;
            position: absolute;
            align-self: flex-end;
            width: 8%;
        }
    }

    .cloud{
        background: $primary-dark;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-block: 5rem;
        .cloud1{
            position: absolute;
            right: 0;
            margin-top: 3rem;
            width: 20%;
            mix-blend-mode: soft-light;
        }
        .cloud3{
            position: absolute;
            margin-left: 40%;
            margin-top: -3%;
            width: 15%;
            mix-blend-mode: soft-light;
        }
        .cloud4{
            position: absolute;
            mix-blend-mode: soft-light;
            align-self: center;
            justify-self: center;
            margin-left: 10rem;
        }
        .cloud-text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-inline: 9rem 0rem;
            gap: 2rem;
            .cloud2{
                position: absolute;
                mix-blend-mode: soft-light;
                align-self: end;
                margin-top: 27rem;
                width: 16%;
            }
            .cloud-title{
                color: $neutral-white;
            }
            .cloud-info{
                color: rgba(255, 255, 255, 0.80);
                width: 85%;
            }
            .btn-primary{
                background: $neutral-white;
                color: $primary-dark;
            }
        }
        .cloud-img{
            display: flex;
            justify-content: center;
            .man{
                mix-blend-mode: multiply;
                z-index: 3;
            }
            .cld{
                mix-blend-mode: soft-light;
                width: 20%;
                position: absolute;
                align-self: end;
                margin-left: 3rem;
                margin-bottom: -1rem;
            }
        }
    }

    .get-in-touch{
        display: flex;
        width: 100%;
        max-height: 100vh;
        gap: 5%;
        background: $neutral-white;
        align-items: center;
        .form-img{
            width: 47%;
            img{
                width: 100%;
                height: 100vh;
                display: block;
            }
        }
        .form-text{
            width: 47%;
            .form{
                width: 80%;
                .contact-form{
                    display: flex;
                    flex-direction: column;
                    .name{
                        display: flex;
                        gap: 6%;
                        padding-bottom: 2rem;
                        .name-50{
                            display: flex;
                            flex-direction: column;
                            width: 47%;
                        }
                    }
                    h3{
                        padding-bottom: 3rem;
                    }
                    label{
                        font-size: 1rem;
                        font-weight: 400;
                        font-family: 'Inter';
                        color: rgba(47, 47, 48, 0.80);
                        padding-bottom: 0.4rem;
                    }
                    input{
                        border-radius: 0.625rem;
                        border: 1px solid rgba(47, 47, 48, 0.20);
                        padding: 0.8rem;
                    }
                    textarea{
                        border-radius: 0.625rem;
                        border: 1px solid rgba(47, 47, 48, 0.20);
                        resize: none;
                        padding: 0.8rem;
                    }
                    .textarea-label{
                        padding-top: 2rem;
                    }
                    button{
                        margin-top: 2rem;
                    }
                }
            }
        }
    }