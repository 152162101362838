.institution{
    .office-hero{
        align-items: center;
        justify-content: space-between;
        .office-hero-text{
            padding-block: 3rem;
        }
        .office-hero-img{
            background: linear-gradient(to left, #FEC4B1 50%, transparent 50%);
            padding-block: 10rem;
            width: 50%;
            // min-height: 90vh;
            img{
                min-height: auto !important;
            }
        }
            .star-text{
                display: flex;
                flex-direction: column;
                width: 60%;
                padding-block: 4rem;
                padding-left: 7rem;
                justify-content: center;
                gap: 2rem;
            }
            .office-star-img{
                width: 80%;
                margin-left: -8rem;
                padding-block: 4rem;
                padding-right: 4rem;
                img{
                    max-width: 100%
                };
            }
    }
}