.contactUs{
    width: 100%;
    .contactUs-title{
        text-align: center;
        padding-block: 8rem;
    }
    .get-in-touch{
        flex-direction: row-reverse;
        .form-text{
            .form{
                margin: auto;
                margin-right: 0;
            }
        }
    }
}