.office-hero{
        display: flex;
        justify-content: space-between;
        .office-hero-text{
            display: flex;
            flex-direction: column;
            width: 50%;
            padding-inline: 7rem 2rem;
            justify-content: center;
            gap: 2rem;
            .hero-info{
                color:rgba(47, 47, 48, 0.80);
                padding-right: 4rem;
            }
            .btn{
                padding-top: 2rem;
                .btn-primary{
                    padding: 0.7rem 2rem;
                    background: transparent;
                    border: 2px solid $primary-dark;
                    color: $primary-dark;
                    flex-direction: row;
                    font-size: 1.875rem;
                    svg{
                        padding-bottom: 3px;
                    }
                }
            }
        }
        .office-hero-img{
            // width: 50%;
            min-height: 90vh;
            img{
                width: 100%;
                min-height: 90vh;
            }
        }
}

.width35{
    width: 35%;
}
    hr{
        height: 1px;
        color: $neutral-70;
        opacity: 1;
        width: 100%;
        filter: drop-shadow(0px -3px 4px rgba(0, 0, 0, 0.25));
    }

    .bg-star{
        background: $primary-40;
        border: none;
        mix-blend-mode: multiply;
    }

    .office-hero{
        .star-info{
            color: rgba(47, 47, 48, 0.80);
        }
        .office-star-img{
            mix-blend-mode: multiply;
        }
    }
    
    .panel{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .panel:not(:first-child) {
        opacity: 0;
        visibility: hidden;
        transform: scale(0.8);
    }

    .animation{
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;
        position: relative;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        // scroll-snap-type: y mandatory;
        .animate{
            width: 100%;
            height: 100vh;
            display: grid;
            grid-template-columns: 1fr 1fr;
            .animate-left{
                background-image: url('../assets/images/bgChecks.png');
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-inline: 7rem 3rem;
                .animate-top{
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 1rem;
                    .edu{
                        width: 20%;
                        margin-left: -1rem;
                    }
                    .sprinkle2{
                        width: 10%;
                        margin-top: -1rem;
                        height: 40%;
                    }
                }
                p{
                    span{
                        color: #DD7B47;
                    }
                }
                .animate-bottom{
                    bottom: 10px;
                    position: absolute;
                }
            }
            .animate-right{
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-inline: 3rem 10rem;
                padding-top: 8rem;
            }
            .letter-shadow{
                display: flex;
                padding-bottom: 1.5rem;
                .shadow{
                    color: rgba(47, 47, 48, 0.05);
                    font-size: 4rem;
                    position: absolute;
                    margin-top: -2rem;
                }
            }
        }
    }

    .mob-animation{
        display: none;
    }
    .animation::-webkit-scrollbar {
        display: none;
    }