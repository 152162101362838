.footer {
  background: #f8f6f1;
  width: 100%;
  padding-block: 5rem 3rem;
  .footer-top {
    display: flex;
    align-items: center;
    gap: 2%;
    width: 65%;
    justify-content: space-between;
    margin: auto;
    padding-bottom: 3rem;
    border-bottom: 1px solid rgba(47, 47, 48, 0.5);
    .logo {
      width: 8%;
      img {
        width: 100%;
      }
    }
    .footer-list {
      display: flex;
      gap: 1.7rem;
      .nav-links {
        text-decoration: none;
        color: $neutral-70;
      }
    }
  }
  .footer-bottom {
    display: flex;
    align-items: center;
    width: 65%;
    justify-content: space-between;
    margin: auto;
    padding-block: 3rem 1rem;
    h2 {
      color: $neutral-70;
    }
    .social-media {
      display: flex;
      gap: 2rem;
    }
  }
  .footer-privacy {
    display: flex;
    align-items: center;
    width: 65%;
    justify-content: space-between;
    margin: auto;
    padding-block: 2rem 1rem;
    .policies {
      display: flex;
      gap: 1rem;
      a{
        text-decoration: underline !important;
      }
      button {
        background: transparent;
        border: none;
        text-decoration: underline;
        font-size: 1rem;
        color: $neutral-70;
      }
    }
  }
}
.ReactModal__Overlay--after-open {
  z-index: 5;
}

/* The Close Button */
.ReactModal__Content {
  z-index: 5 !important;
  padding: 0rem !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  width: 60%;
  margin: auto;

  .close {
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }

  .modal-header {
    padding: 0.8rem 1.5rem;
    border-bottom: 1px solid $neutral-20;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      line-height: 1.5;
      font-size: 2.5rem;
    }
  }

  .modal-body {
    padding: 1rem 1.5rem 0rem 1.5rem;
    border-bottom: 1px solid $neutral-20;
    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.0625em;
      margin-bottom: 1.2rem;
    }
    ul {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.0625em;
      padding: 0rem 2.5rem;
      margin-bottom: 1.2rem;
    }
    h3 {
      margin-block: 1.4rem 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      font-size: 1.5rem;
    }
  }
  .modal-footer {
    padding: 1rem 1.5rem;
    text-align: end;
  }
}
