@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
.col-primary {
  color: #1769E6;
}

.col-grey {
  color: #2F2F30;
}

.fam-mnt {
  font-family: "Montserrat" !important;
}

.fam-int {
  font-family: "Inter" !important;
}

.fam-mul {
  font-family: "Mulish" !important;
}

.font-12 {
  font-size: 0.75rem;
}

.font-14 {
  font-size: 0.875rem;
}

.font-16 {
  font-size: 1rem;
}

.font-18 {
  font-size: 1.125rem;
}

.font-20 {
  font-size: 1.25rem;
}

.font-22 {
  font-size: 1.375rem;
}

.font-24 {
  font-size: 1.5rem;
}

.font-26 {
  font-size: 1.625rem;
}

.font-28 {
  font-size: 1.75rem;
}

.font-30 {
  font-size: 1.875rem;
}

.font-31 {
  font-size: 2.125rem;
}

.font-32 {
  font-size: 2.5rem;
}

.font-33 {
  font-size: 2.25rem;
}

.font-36 {
  font-size: 3.125rem;
}

.font-38 {
  font-size: 3.5rem;
}

.font-39 {
  font-size: 3.75rem;
}

.font-40 {
  font-size: 4.375rem;
}

.font-42 {
  font-size: 6.25rem;
}

.font-50 {
  font-size: 15.625rem;
}

.wg-1 {
  font-weight: 100;
}

.wg-2 {
  font-weight: 200;
}

.wg-3 {
  font-weight: 300;
}

.wg-4 {
  font-weight: 400;
}

.wg-5 {
  font-weight: 500;
}

.wg-6 {
  font-weight: 600;
}

.wg-7 {
  font-weight: 700;
}

.wg-8 {
  font-weight: 800;
}

.wg-9 {
  font-weight: 900;
}

.btn-primary {
  display: inline-flex;
  padding: 0.75rem 1.875rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  background-color: #1769E6;
  border-radius: 3.125rem;
  border: none;
  color: white;
  font-size: 1.25rem;
  font-family: "Mulish";
  font-weight: 700;
  cursor: pointer;
}
.btn-primary a {
  text-decoration: none !important;
  color: white;
}

button {
  cursor: pointer;
}

.navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem 1.5rem;
  z-index: 999;
  height: 100%;
  max-height: 6rem;
  border-bottom: 1px solid rgba(47, 47, 48, 0.1);
}
.navigation .mob-nav-logo {
  display: inline;
}
.navigation .mob-nav-logo img {
  max-width: 12rem;
}
.navigation .main-links > .active {
  font-weight: 700;
}

.nav-ham {
  align-self: center;
  padding-left: 1rem;
}
.nav-ham svg > path {
  stroke: #000000;
}

.nav-btn {
  align-self: center;
  align-items: center;
  display: flex;
}

.open-nav {
  padding: 1.5rem;
  justify-content: flex-start !important;
}
.open-nav .nav-bottom {
  flex-direction: column !important;
  gap: 1rem;
  padding-top: 1rem;
}
.open-nav .nav-top .routes {
  align-items: center;
  border-bottom: 1px solid rgba(47, 47, 48, 0.1);
  padding-bottom: 1rem;
  gap: 1rem;
}

.navigation-wrapper {
  width: 100%;
  height: 100vh;
  z-index: 999;
  position: fixed;
  top: 0;
  background-color: #F8F6F1;
  left: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  transform: translate(100%, 0);
  transition: transform 0.2s ease 0s;
}
.navigation-wrapper #logo {
  max-width: 11rem;
  display: none;
}
.navigation-wrapper #logo img {
  width: 100%;
  height: auto;
}
.navigation-wrapper .nav-top,
.navigation-wrapper .nav-bottom {
  width: 100%;
}
.navigation-wrapper .nav-bottom {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
}
.navigation-wrapper .nav-bottom .blog-btn {
  background-color: transparent;
  color: #1769E6;
  border: 1px solid #1769E6;
}
.navigation-wrapper .nav-bottom button a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: block;
  color: white;
}
.navigation-wrapper .nav-bottom a {
  font-size: 1.05rem;
  color: inherit;
  text-decoration: none;
}
.navigation-wrapper .mob-nav-logo {
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #E5E5EA;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navigation-wrapper .mob-nav-logo span {
  color: #2F2F30;
  font-size: 37px;
}
.navigation-wrapper .routes {
  list-style-type: none;
  padding-top: 1rem;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  font-size: 1.5rem;
}
.navigation-wrapper .routes .main-links {
  padding: 0.5rem 0.2rem;
  height: auto !important;
  position: relative;
}
.navigation-wrapper .routes .main-links .nav-links {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Inter";
  color: #2F2F30 !important;
}
.navigation-wrapper .routes .main-links:nth-child(2) p {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.nav-links .btn-primary {
  background: transparent;
  border: 2px solid #1769E6;
  color: #1769E6;
}

.nav-white .navigation-wrapper .routes {
  display: none !important;
}
.nav-white .navigation-wrapper .defend {
  display: none !important;
}
.nav-white .navigation-wrapper .back {
  justify-content: end !important;
}

.nav-black .navigation-wrapper .back {
  display: none !important;
}

@media screen and (min-width: 67rem) {
  .navigation {
    padding-inline: 7rem !important;
  }
  .navigation .nav-ham {
    display: none;
  }
  .navigation .mob-nav-logo {
    display: none;
  }
  .navigation-wrapper {
    background-color: transparent;
    transform: translateX(0%) !important;
    position: relative;
    height: auto;
    flex-flow: row;
    align-items: center !important;
    width: 100% !important;
    max-width: unset !important;
  }
  .navigation-wrapper .nav-bottom {
    width: 35%;
  }
  .navigation-wrapper .nav-bottom .blog-btn {
    margin-right: 1.5rem;
  }
  .navigation-wrapper #logo {
    width: 25%;
    display: inline;
  }
  .navigation-wrapper .nav-top {
    width: 45%;
  }
  .navigation-wrapper .nav-top .mob-nav-logo {
    display: none;
  }
  .navigation-wrapper .nav-top .routes {
    padding-block: 2rem !important;
    flex-flow: row !important;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.2rem !important;
  }
}
@media screen and (min-width: 1920px) {
  .navigation-wrapper .nav-top .routes {
    font-size: 1.2rem !important;
  }
  .why {
    grid-template-columns: 3fr 2fr !important;
  }
  .why .why-text .why-card {
    left: -14vw !important;
  }
  .benefits {
    height: 60vh !important;
  }
  .benefits .benefits-img img {
    width: 150% !important;
  }
  .about {
    min-height: 70vh !important;
  }
  .footer .footer-top {
    width: 60% !important;
  }
  .footer .footer-top .footer-list {
    gap: 3rem !important;
  }
  .footer .footer-bottom {
    width: 60% !important;
  }
  .footer .footer-privacy {
    width: 60% !important;
  }
  .office-hero .office-hero-text {
    padding-inline: 7rem 7rem !important;
  }
  .institution .office-hero .office-star-img {
    margin-left: 0rem !important;
    padding-right: 0rem !important;
  }
}
@media screen and (max-width: 960px) and (min-width: 630px) {
  .heard .mob-heard-card {
    gap: 2rem !important;
  }
  .heard .mob-heard-card div img {
    width: 35% !important;
  }
  .inflation-guy-hero h2 {
    padding-bottom: 6rem !important;
    font-size: 2.25rem !important;
  }
  .inflation-guy-hero .desktop-img {
    display: none !important;
  }
  .inflation-guy-hero .mob-img {
    display: block !important;
  }
  .about .about-card .inflationGuy {
    width: 25% !important;
    margin-top: -27% !important;
  }
  .how .mob-cards .how-card .how-img img {
    width: 40% !important;
  }
}
@media screen and (max-width: 630px) and (min-width: 450px) {
  .about .about-card .inflationGuy {
    margin-top: -40% !important;
    width: 37% !important;
  }
  .how .mob-cards .how-card .how-img img {
    width: 60% !important;
  }
  .inflation-guy-hero h2 {
    padding-bottom: 5rem !important;
    font-size: 1.6rem !important;
  }
  .inflation-guy-hero .desktop-img {
    display: none !important;
  }
  .inflation-guy-hero .mob-img {
    display: block !important;
  }
}
@media screen and (max-width: 450px) {
  .about .about-card .inflationGuy {
    width: 55% !important;
    margin-top: -60% !important;
  }
  .how .mob-cards .how-card .how-img img {
    width: 65% !important;
  }
  .inflation-guy-hero h2 {
    padding-bottom: 3rem !important;
    font-size: 1.25rem !important;
  }
  .inflation-guy-hero .desktop-img {
    display: none !important;
  }
  .inflation-guy-hero .mob-img {
    display: block !important;
  }
}
@media screen and (max-width: 1050px) {
  .width35 {
    width: 100% !important;
  }
  .why {
    grid-template-columns: auto !important;
    height: auto !important;
    padding-block: 1rem !important;
  }
  .why .why-img .sprinkle1 {
    top: 0;
    right: 0;
    left: auto !important;
    width: 15%;
    margin: 0rem !important;
  }
  .why .why-img .why-img1 {
    width: 100% !important;
    border-radius: 0rem !important;
    padding-top: 3rem;
  }
  .why .why-text {
    display: block !important;
    width: 90% !important;
    top: -70px;
    margin: auto;
  }
  .why .why-text .why-card {
    left: 0 !important;
  }
  .why .why-text .why-card .why-title {
    font-size: 1.875rem !important;
  }
  .why .why-text .why-card h2 {
    font-size: 1.25rem !important;
  }
  .why .why-text .why-card p {
    font-size: 1rem !important;
  }
  .why .why-text .why-card .search {
    display: none !important;
  }
  .why .why-text .sprinkle1 {
    left: 0;
  }
  .benefits {
    flex-direction: column;
    padding: 4rem 1rem 2rem 1rem;
    height: auto !important;
  }
  .benefits .sprinkle1 {
    display: none;
  }
  .benefits .sprinkle2 {
    display: none;
  }
  .benefits .sprinkle3 {
    display: none;
  }
  .benefits .benefits-img {
    display: none;
  }
  .benefits .mob-benefits-img {
    display: block !important;
  }
  .benefits .benefits-text {
    width: 100% !important;
    gap: 1rem !important;
    padding-bottom: 2rem;
  }
  .benefits .benefits-text h2 {
    font-size: 1.875rem !important;
  }
  .benefits .benefits-text p {
    font-size: 1rem !important;
  }
  .benefits .benefits-text .btn-primary {
    font-size: 1.25rem !important;
  }
  .benefits .benefits-text .btn-primary svg {
    padding-bottom: 6px !important;
  }
  .how {
    padding-block: 3rem !important;
  }
  .how .how-title {
    font-size: 1.875rem !important;
    padding-bottom: 2rem !important;
  }
  .how .slider {
    display: none;
  }
  .how .mob-cards {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    padding-inline: 1rem;
    gap: 1rem;
  }
  .how .mob-cards .how-card {
    background: #fff;
  }
  .how .mob-cards .how-card .how-card-title {
    font-size: 1.25rem !important;
  }
  .about {
    padding-inline: 1rem;
    min-height: 70vh !important;
  }
  .about .about-card {
    width: 100% !important;
    padding: 8rem 1rem 2rem 1rem !important;
  }
  .about .about-card .about-info {
    width: 100% !important;
  }
  .about .about-card .about-info .about-title {
    font-size: 1.875rem !important;
  }
  .about .about-card .about-info .about-detail {
    font-size: 1rem !important;
  }
  .about .about-card .about-info .btn-primary {
    font-size: 1.25rem !important;
  }
  .about .about-card .inflationGuy {
    margin-left: 0 !important;
  }
  .about .sprinkle1 {
    right: 20px;
    margin-top: -5rem;
    margin-inline-start: 0vw !important;
  }
  .about .dollar {
    display: none;
  }
  .cloud {
    grid-template-columns: 1fr !important;
    gap: 3rem;
    padding-block: 3rem !important;
  }
  .cloud .cloud1 {
    margin-left: -18%;
    align-self: center;
    width: 40% !important;
    left: 0;
  }
  .cloud .cloud3 {
    display: none;
  }
  .cloud .cloud4 {
    display: none;
  }
  .cloud .cloud-text {
    padding-inline: 1rem !important;
    gap: 1rem !important;
  }
  .cloud .cloud-text .cloud2 {
    margin-top: 20rem !important;
    width: 35% !important;
    margin-right: -2.5rem;
  }
  .cloud .cloud-text .cloud-title {
    font-size: 1.875rem !important;
  }
  .cloud .cloud-text .cloud-info {
    font-size: 1rem !important;
  }
  .cloud .cloud-text .btn-primary {
    font-size: 1.25rem !important;
  }
  .cloud .cloud-img {
    justify-content: center;
  }
  .cloud .cloud-img .man {
    width: 80% !important;
  }
  .cloud .cloud-img .cld {
    width: 65% !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }
  .get-in-touch {
    flex-direction: column !important;
    max-height: none !important;
  }
  .get-in-touch .form-img {
    width: 100% !important;
  }
  .get-in-touch .form-img img {
    height: 100% !important;
  }
  .get-in-touch .form-text {
    width: 100% !important;
    padding-inline: 0rem !important;
  }
  .get-in-touch .form-text .form {
    padding-inline: 1rem !important;
    padding-block: 3rem;
    width: 100% !important;
  }
  .get-in-touch .form-text .form .contact-form h3 {
    padding-bottom: 2rem !important;
  }
  .get-in-touch .form-text .form .contact-form .name {
    flex-direction: column;
    padding-bottom: 1.4rem !important;
    gap: 1.4rem !important;
  }
  .get-in-touch .form-text .form .contact-form .name .name-50 {
    width: 100% !important;
  }
  .get-in-touch .form-text .form .contact-form label {
    padding-bottom: 0.3rem !important;
  }
  .get-in-touch .form-text .form .contact-form .textarea-label {
    padding-top: 1.4rem !important;
  }
  .get-in-touch .form-text .form .contact-form button {
    margin-top: 1.4rem !important;
  }
  .footer {
    padding-block: 3rem !important;
  }
  .footer .footer-top {
    flex-direction: column;
    align-items: start !important;
    width: 90% !important;
    padding-inline: 1rem;
    padding-bottom: 2rem !important;
    gap: 2rem !important;
  }
  .footer .footer-top .logo {
    width: 17% !important;
  }
  .footer .footer-top .footer-list {
    flex-direction: column;
    gap: 1rem !important;
  }
  .footer .footer-bottom {
    flex-direction: column;
    align-items: start !important;
    width: 90% !important;
    padding-block: 2rem 0rem !important;
    padding-inline: 1rem;
    gap: 1rem;
  }
  .footer .footer-bottom h2 {
    font-size: 1.875rem !important;
  }
  .footer .footer-bottom .social-media {
    gap: 1.5rem !important;
  }
  .footer .footer-privacy {
    flex-direction: column;
    align-items: start !important;
    width: 90% !important;
    padding-block: 2rem 0rem !important;
    gap: 1rem;
    padding-inline: 1rem;
    padding-block: 2.5rem 0rem !important;
  }
  .footer .footer-privacy .policies {
    gap: 0.4rem !important;
  }
  .office-hero {
    flex-direction: column !important;
    padding-block: 3rem 0rem;
    min-height: auto !important;
    gap: 2rem;
  }
  .office-hero .office-hero-text {
    width: 100% !important;
    padding-inline: 1rem !important;
    gap: 1rem !important;
  }
  .office-hero .office-hero-text .hero-title {
    font-size: 2.5rem !important;
  }
  .office-hero .office-hero-text .hero-subtitle {
    font-size: 1.25rem !important;
  }
  .office-hero .office-hero-text .hero-info {
    padding-right: 0rem !important;
    font-size: 1rem !important;
  }
  .office-hero .office-hero-text .btn {
    padding-top: 0rem !important;
  }
  .office-hero .office-hero-text .btn .btn-primary {
    font-size: 1.25rem !important;
    padding: 0.5rem 2rem !important;
  }
  .office-hero .office-hero-text .btn .btn-primary svg {
    width: 15%;
  }
  .office-hero .office-hero-text .star-title {
    font-size: 1.875rem !important;
    padding-bottom: 1rem;
  }
  .office-hero .office-hero-text .star-info {
    font-size: 1rem !important;
    padding-bottom: 1rem;
  }
  .office-hero .office-hero-img {
    width: 100% !important;
    min-height: auto !important;
  }
  .office-hero .office-hero-img img {
    width: 100% !important;
    min-height: auto !important;
  }
  .office-hero .office-star-img {
    text-align: center;
  }
  .office-hero .office-star-img img {
    width: 50%;
  }
  .pin-spacer {
    display: none !important;
  }
  .mob-animation {
    display: block !important;
    width: 100%;
  }
  .mob-animation .mob-animate {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .mob-animation .mob-animate .mob-animate-left {
    background-image: url("../assets/images/bgChecks.png");
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline: 1rem !important;
    padding-bottom: 1rem;
    padding-top: 3.5rem;
  }
  .mob-animation .mob-animate .mob-animate-left .mob-animate-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
  }
  .mob-animation .mob-animate .mob-animate-left .mob-animate-top .edu {
    width: 20%;
    margin-left: -1rem;
  }
  .mob-animation .mob-animate .mob-animate-left .mob-animate-top p span {
    color: #dd7b47;
  }
  .mob-animation .mob-animate .mob-animate-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline: 1rem !important;
    padding-top: 3rem !important;
    padding-bottom: 1.5rem !important;
  }
  .mob-animation .mob-animate .letter-shadow {
    display: flex;
    padding-bottom: 1.5rem;
  }
  .mob-animation .mob-animate .letter-shadow .shadow {
    color: rgba(47, 47, 48, 0.05);
    position: absolute;
    font-size: 3.125rem !important;
    margin-top: -1.5rem !important;
  }
  .institution .office-hero {
    min-height: auto !important;
  }
  .institution .office-hero .office-hero-text {
    padding-block: 0rem !important;
  }
  .institution .office-hero .office-hero-img {
    padding-block: 4rem !important;
    padding-right: 5rem;
    min-height: auto !important;
    background: -webkit-linear-gradient(right, #fec4b1 50%, #f8f6f1 50%) !important;
  }
  .institution .office-hero .office-hero-img img {
    margin-left: 0rem !important;
  }
  .institution .office-hero .star-text {
    width: auto !important;
    padding-block: 0rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem;
  }
  .institution .office-hero .star-text .star-title {
    font-size: 1.875rem !important;
  }
  .institution .office-hero .star-text .star-info {
    font-size: 1rem !important;
  }
  .institution .office-hero .office-star-img {
    padding-bottom: 2rem;
    padding-left: 2rem;
    margin-top: -3rem;
    width: 100% !important;
    margin-left: 0rem !important;
    padding-block: 0rem !important;
    padding-right: 0rem !important;
  }
  .institution .office-hero .office-star-img img {
    width: 100%;
  }
  .individual .office-hero {
    padding-inline: 0rem !important;
    gap: 0rem !important;
    min-height: auto !important;
  }
  .individual .office-hero .star-text {
    width: 100% !important;
    padding-left: 1rem;
    padding-right: 1rem !important;
    gap: 1rem !important;
  }
  .individual .office-hero .star-text .star-title {
    font-size: 1.875rem !important;
  }
  .individual .office-hero .star-text .star-info {
    font-size: 1rem !important;
  }
  .individual .office-hero .office-star-img {
    padding-block: 2rem !important;
    padding-inline: 1rem !important;
    width: 100% !important;
  }
  .inflation-page .animationBook {
    display: none;
  }
  .inflation-guy {
    padding-block: 3.5rem !important;
  }
  .inflation-guy .inflation-info {
    gap: 1.5rem !important;
    width: 91% !important;
  }
  .inflation-guy .inflation-info h2 {
    font-size: 1.875rem !important;
  }
  .inflation-guy .inflation-info p {
    font-size: 1rem !important;
  }
  .inflation-guy .inflation-info h4 {
    font-size: 1.125rem !important;
  }
  .inflation-guy .inflation-info .sprinkle1 {
    display: none;
  }
  .inflation-guy .inflationMoney {
    display: none;
  }
  .heard .mob-heard-card {
    display: flex !important;
    flex-direction: column;
  }
  .heard .mob-heard-card div {
    width: 100%;
    display: flex;
    min-height: 15vh;
    padding-inline: 2rem;
    justify-content: center;
    gap: 10%;
    align-items: center;
  }
  .heard .mob-heard-card div img {
    width: 45%;
    height: 100%;
  }
  .reviews {
    padding: 4rem 1rem !important;
    gap: 1rem !important;
  }
  .reviews .review-row {
    flex-direction: column !important;
    gap: 1rem !important;
  }
  .reviews .review-row .review-card {
    width: 100% !important;
    gap: 1rem !important;
    margin-left: none !important;
  }
  .reviews .review-row .review-card h2 {
    font-size: 1.625rem !important;
  }
  .reviews .review-row .review-card p {
    font-size: 1.125rem !important;
    min-height: 4rem !important;
  }
  .reviews .review-row .review-card h5 {
    font-size: 1.25rem !important;
  }
  .reviews .ml {
    margin-left: 0% !important;
  }
  .podcasts {
    padding: 4rem 1rem !important;
    gap: 1.5rem !important;
  }
  .podcasts .head h2 {
    font-size: 1.875rem !important;
  }
  .podcasts .head .btn-primary {
    display: none !important;
  }
  .podcasts .podcast-body {
    flex-direction: column;
    gap: 0rem !important;
  }
  .podcasts .podcast-body .podcast-img {
    width: 75% !important;
  }
  .podcasts .podcast-body .podcast-text {
    width: 100% !important;
  }
  .podcasts .podcast-body .podcast-text .pt {
    padding-top: 1rem !important;
  }
  .podcasts .podcast-body .podcast-text .podcast-row {
    padding-block: 1rem !important;
  }
  .podcasts .podcast-body .podcast-text .podcast-row .podcast-info h2 {
    font-size: 1rem !important;
  }
  .podcasts .podcast-body .podcast-text .podcast-row .podcast-info p {
    font-size: 0.875rem !important;
  }
  .podcasts .podcast-body .podcast-text .button-bottom {
    padding-top: 1rem;
    display: block !important;
    text-align: center;
  }
  .photos {
    display: none !important;
  }
  .mob-photos {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
    gap: 1.2rem;
  }
  .mob-photos .p1 {
    width: 100%;
    height: 100%;
    border-radius: 1.25rem;
  }
  .mob-photos .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .mob-photos .row img {
    width: 47%;
    border-radius: 1.25rem;
  }
  .spotify {
    padding: 4rem 1rem !important;
    flex-direction: column;
    gap: 0rem !important;
  }
  .spotify .spotify-img img {
    width: 80% !important;
  }
  .spotify .spotify-info {
    gap: 1.5rem !important;
  }
  .spotify .spotify-info h2 {
    font-size: 1.875rem !important;
  }
  .spotify .spotify-info p {
    font-size: 1.25rem !important;
  }
  .spotify .spotify-info .spotify-icon {
    flex-direction: column;
    gap: 1rem !important;
  }
  .spotify .spotify-info .spotify-icon img {
    width: 80% !important;
  }
  .mobAnimationBook {
    display: flex !important;
    flex-direction: column;
    padding: 3rem 1rem;
  }
  .mobAnimationBook .mobAnimateBook {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .mobAnimationBook .mobAnimateBook .mobAnimateBookLeft .mob-book-img {
    width: 50%;
  }
  .mobAnimationBook .mobAnimateBook .mobAnimateBookLeft .mob-book-img img {
    width: 100%;
  }
  .mobAnimationBook .mobAnimateBook .mobAnimateBookRight .mob-book-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .mobAnimationBook .mobAnimateBook .mobAnimateBookRight .mob-book-text p {
    color: rgba(47, 47, 48, 0.8);
  }
  .contactUs .contactUs-title {
    padding-block: 5rem !important;
  }
  .contactUs .contactUs-title h2 {
    font-size: 2.5rem !important;
  }
  .ReactModal__Content {
    width: 100% !important;
    margin: -2.5rem !important;
  }
}
@media screen and (max-width: 1410px) and (min-width: 960px) {
  .animation .panel .animate .animate-left .letter-shadow .font-small {
    font-size: 2.45rem !important;
  }
}
@media screen and (max-width: 1000px) {
  .landing .mob-hero {
    display: flex !important;
    width: 70vw !important;
  }
  .landing .border {
    display: none;
  }
  .landing div {
    padding-left: 0rem !important;
  }
  .landing div .fill {
    display: none;
  }
  .landing div img {
    display: none;
  }
  .landing .btn {
    padding-top: 0rem !important;
    text-align: center;
  }
  .landing .btn .btn-primary {
    font-size: 1.5rem !important;
    gap: 0rem;
  }
  .landing .btn .btn-primary svg {
    padding-bottom: 5px;
  }
}
.landing {
  padding-block: 5rem;
}
.landing .mob-hero {
  display: none;
}
.landing .border {
  position: relative;
  z-index: 2;
  color: #F8F6F1;
  text-shadow: -2px -2px 0 #1769E6, 2px -2px 0 #1769E6, -2px 2px 0 #1769E6, 2px 2px 0 #1769E6;
}
.landing .pd {
  padding-left: 10rem;
}
.landing p {
  font-size: 9.375rem;
  font-weight: 800;
  line-height: 8.5rem;
}
.landing div {
  padding-left: 15rem;
  position: relative;
}
.landing div .fill {
  color: #1769E6;
  position: relative;
  z-index: 2;
}
.landing div img {
  position: absolute;
  bottom: -115px;
  right: -150px;
  z-index: 0;
}
.landing .btn {
  padding-top: 2rem;
}
.landing .btn .btn-primary {
  padding: 0.7rem 2rem;
  background: transparent;
  border: 2px solid #1769E6;
  color: #1769E6;
  flex-direction: row;
  font-size: 1.875rem;
}
.landing .btn .btn-primary svg {
  padding-bottom: 3px;
}

.why {
  width: 100%;
  background: #D0E1FB;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  padding-block: 4rem;
}
.why .why-img {
  position: relative;
  text-align: end;
}
.why .why-img .sprinkle1 {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 2rem;
}
.why .why-img .why-img1 {
  width: 90%;
  border-radius: 1.25rem;
}
.why .why-text {
  width: 100%;
  display: contents;
  position: relative;
}
.why .why-text .why-card {
  background: white;
  border: 1px solid #2F2F30;
  border-radius: 1.25rem;
  padding: 2rem 1.875rem;
  position: relative;
  left: -8vw;
}
.why .why-text .why-card .why-title {
  color: #1769E6;
  padding-bottom: 1rem;
}
.why .why-text .why-card h2 {
  color: #2F2F30;
  padding-bottom: 1rem;
}
.why .why-text .why-card p {
  padding-bottom: 1rem;
  color: rgba(47, 47, 48, 0.8);
}
.why .why-text .why-card .search {
  width: 8%;
  position: absolute;
  top: -20px;
  right: -15px;
}
.why .why-text .sprinkle1 {
  right: 2vw;
  position: absolute;
  align-self: baseline;
}

.benefits {
  padding-block: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.benefits .benefits-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  gap: 2.5rem;
  text-align: center;
  color: #2F2F30;
}
.benefits .benefits-text p {
  color: rgba(47, 47, 48, 0.8);
}
.benefits .benefits-text .btn .btn-primary {
  padding: 0.7rem 2rem;
  background: transparent;
  border: 2px solid #2F2F30;
  color: #2F2F30;
  flex-direction: row;
  font-size: 1.875rem;
}
.benefits .benefits-text .btn .btn-primary svg {
  padding-bottom: 3px;
}
.benefits .benefits-img {
  position: absolute;
  left: 0;
  margin-bottom: -5rem;
  align-self: end;
}
.benefits .benefits-img img {
  width: 120%;
}
.benefits .sprinkle1 {
  align-self: baseline;
  position: absolute;
  right: 8vw;
}
.benefits .sprinkle2 {
  position: absolute;
  align-self: baseline;
  left: 25vw;
}
.benefits .sprinkle3 {
  position: absolute;
  align-self: flex-end;
  right: 20vw;
}
.benefits .mob-benefits-img {
  display: none;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1750px);
  }
}
.slider {
  overflow: hidden;
  position: relative;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .how-carousel {
  animation: scroll 40s linear infinite;
  display: flex;
  background: #FEFEFE;
}
.slider .how-carousel .how-card {
  margin-right: 2.5rem;
  min-width: 30vh;
}

.how {
  background: #FEFEFE;
  text-align: center;
  padding-block: 8rem;
  width: 100%;
}
.how .how-title {
  padding-bottom: 5rem;
}
.how .mob-cards {
  display: none;
}
.how .how-card {
  text-align: center;
  padding: 2rem 1rem;
  border-radius: 1.25rem;
  border: 2px solid #2F2F30;
  background: #F8F6F1;
  box-shadow: 5px 5px 50px 0px rgba(47, 47, 48, 0.05);
}
.how .how-card .how-card-title {
  text-wrap: wrap;
  min-height: 3.8rem;
}
.how .how-card .how-img {
  padding-top: 2rem;
}
.how .how-card .how-img img {
  width: 65%;
}

.about {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FACC71 100%);
  display: flex;
  min-height: 90vh;
  align-items: center;
  width: 100%;
  padding-top: 8rem;
  justify-content: center;
}
.about .about-card {
  border-radius: 1.875rem;
  border: 1px solid #2F2F30;
  background: #D0E1FB;
  display: flex;
  gap: 1.5rem;
  padding: 4rem 3rem;
  width: 60%;
  margin-bottom: 3rem;
}
.about .about-card .about-info {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: #2F2F30;
}
.about .about-card .about-detail {
  color: rgba(47, 47, 48, 0.8);
}
.about .about-card .btn-primary {
  color: #2F2F30;
  background: white;
}
.about .about-card .inflationGuy {
  width: 23%;
  position: absolute;
  margin-top: -12%;
  margin-left: 30%;
}
.about .sprinkle1 {
  position: absolute;
  align-self: baseline;
  margin-inline-start: -75vw;
}
.about .dollar {
  right: 0;
  position: absolute;
  align-self: flex-end;
  width: 8%;
}

.cloud {
  background: #1769E6;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-block: 5rem;
}
.cloud .cloud1 {
  position: absolute;
  right: 0;
  margin-top: 3rem;
  width: 20%;
  mix-blend-mode: soft-light;
}
.cloud .cloud3 {
  position: absolute;
  margin-left: 40%;
  margin-top: -3%;
  width: 15%;
  mix-blend-mode: soft-light;
}
.cloud .cloud4 {
  position: absolute;
  mix-blend-mode: soft-light;
  align-self: center;
  justify-self: center;
  margin-left: 10rem;
}
.cloud .cloud-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 9rem 0rem;
  gap: 2rem;
}
.cloud .cloud-text .cloud2 {
  position: absolute;
  mix-blend-mode: soft-light;
  align-self: end;
  margin-top: 27rem;
  width: 16%;
}
.cloud .cloud-text .cloud-title {
  color: white;
}
.cloud .cloud-text .cloud-info {
  color: rgba(255, 255, 255, 0.8);
  width: 85%;
}
.cloud .cloud-text .btn-primary {
  background: white;
  color: #1769E6;
}
.cloud .cloud-img {
  display: flex;
  justify-content: center;
}
.cloud .cloud-img .man {
  mix-blend-mode: multiply;
  z-index: 3;
}
.cloud .cloud-img .cld {
  mix-blend-mode: soft-light;
  width: 20%;
  position: absolute;
  align-self: end;
  margin-left: 3rem;
  margin-bottom: -1rem;
}

.get-in-touch {
  display: flex;
  width: 100%;
  max-height: 100vh;
  gap: 5%;
  background: white;
  align-items: center;
}
.get-in-touch .form-img {
  width: 47%;
}
.get-in-touch .form-img img {
  width: 100%;
  height: 100vh;
  display: block;
}
.get-in-touch .form-text {
  width: 47%;
}
.get-in-touch .form-text .form {
  width: 80%;
}
.get-in-touch .form-text .form .contact-form {
  display: flex;
  flex-direction: column;
}
.get-in-touch .form-text .form .contact-form .name {
  display: flex;
  gap: 6%;
  padding-bottom: 2rem;
}
.get-in-touch .form-text .form .contact-form .name .name-50 {
  display: flex;
  flex-direction: column;
  width: 47%;
}
.get-in-touch .form-text .form .contact-form h3 {
  padding-bottom: 3rem;
}
.get-in-touch .form-text .form .contact-form label {
  font-size: 1rem;
  font-weight: 400;
  font-family: "Inter";
  color: rgba(47, 47, 48, 0.8);
  padding-bottom: 0.4rem;
}
.get-in-touch .form-text .form .contact-form input {
  border-radius: 0.625rem;
  border: 1px solid rgba(47, 47, 48, 0.2);
  padding: 0.8rem;
}
.get-in-touch .form-text .form .contact-form textarea {
  border-radius: 0.625rem;
  border: 1px solid rgba(47, 47, 48, 0.2);
  resize: none;
  padding: 0.8rem;
}
.get-in-touch .form-text .form .contact-form .textarea-label {
  padding-top: 2rem;
}
.get-in-touch .form-text .form .contact-form button {
  margin-top: 2rem;
}

.footer {
  background: #f8f6f1;
  width: 100%;
  padding-block: 5rem 3rem;
}
.footer .footer-top {
  display: flex;
  align-items: center;
  gap: 2%;
  width: 65%;
  justify-content: space-between;
  margin: auto;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgba(47, 47, 48, 0.5);
}
.footer .footer-top .logo {
  width: 8%;
}
.footer .footer-top .logo img {
  width: 100%;
}
.footer .footer-top .footer-list {
  display: flex;
  gap: 1.7rem;
}
.footer .footer-top .footer-list .nav-links {
  text-decoration: none;
  color: #2F2F30;
}
.footer .footer-bottom {
  display: flex;
  align-items: center;
  width: 65%;
  justify-content: space-between;
  margin: auto;
  padding-block: 3rem 1rem;
}
.footer .footer-bottom h2 {
  color: #2F2F30;
}
.footer .footer-bottom .social-media {
  display: flex;
  gap: 2rem;
}
.footer .footer-privacy {
  display: flex;
  align-items: center;
  width: 65%;
  justify-content: space-between;
  margin: auto;
  padding-block: 2rem 1rem;
}
.footer .footer-privacy .policies {
  display: flex;
  gap: 1rem;
}
.footer .footer-privacy .policies a {
  text-decoration: underline !important;
}
.footer .footer-privacy .policies button {
  background: transparent;
  border: none;
  text-decoration: underline;
  font-size: 1rem;
  color: #2F2F30;
}

.ReactModal__Overlay--after-open {
  z-index: 5;
}

/* The Close Button */
.ReactModal__Content {
  z-index: 5 !important;
  padding: 0rem !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  width: 60%;
  margin: auto;
}
.ReactModal__Content::-webkit-scrollbar {
  display: none;
}
.ReactModal__Content .close {
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}
.ReactModal__Content .modal-header {
  padding: 0.8rem 1.5rem;
  border-bottom: 1px solid #E5E5EA;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ReactModal__Content .modal-header h1 {
  line-height: 1.5;
  font-size: 2.5rem;
}
.ReactModal__Content .modal-body {
  padding: 1rem 1.5rem 0rem 1.5rem;
  border-bottom: 1px solid #E5E5EA;
}
.ReactModal__Content .modal-body p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.0625em;
  margin-bottom: 1.2rem;
}
.ReactModal__Content .modal-body ul {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.0625em;
  padding: 0rem 2.5rem;
  margin-bottom: 1.2rem;
}
.ReactModal__Content .modal-body h3 {
  margin-block: 1.4rem 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.5rem;
}
.ReactModal__Content .modal-footer {
  padding: 1rem 1.5rem;
  text-align: end;
}

.office-hero {
  display: flex;
  justify-content: space-between;
}
.office-hero .office-hero-text {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-inline: 7rem 2rem;
  justify-content: center;
  gap: 2rem;
}
.office-hero .office-hero-text .hero-info {
  color: rgba(47, 47, 48, 0.8);
  padding-right: 4rem;
}
.office-hero .office-hero-text .btn {
  padding-top: 2rem;
}
.office-hero .office-hero-text .btn .btn-primary {
  padding: 0.7rem 2rem;
  background: transparent;
  border: 2px solid #1769E6;
  color: #1769E6;
  flex-direction: row;
  font-size: 1.875rem;
}
.office-hero .office-hero-text .btn .btn-primary svg {
  padding-bottom: 3px;
}
.office-hero .office-hero-img {
  min-height: 90vh;
}
.office-hero .office-hero-img img {
  width: 100%;
  min-height: 90vh;
}

.width35 {
  width: 35%;
}

hr {
  height: 1px;
  color: #2F2F30;
  opacity: 1;
  width: 100%;
  filter: drop-shadow(0px -3px 4px rgba(0, 0, 0, 0.25));
}

.bg-star {
  background: #D0E1FB;
  border: none;
  mix-blend-mode: multiply;
}

.office-hero .star-info {
  color: rgba(47, 47, 48, 0.8);
}
.office-hero .office-star-img {
  mix-blend-mode: multiply;
}

.panel {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.panel:not(:first-child) {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);
}

.animation {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.animation .animate {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.animation .animate .animate-left {
  background-image: url("../assets/images/bgChecks.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 7rem 3rem;
}
.animation .animate .animate-left .animate-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}
.animation .animate .animate-left .animate-top .edu {
  width: 20%;
  margin-left: -1rem;
}
.animation .animate .animate-left .animate-top .sprinkle2 {
  width: 10%;
  margin-top: -1rem;
  height: 40%;
}
.animation .animate .animate-left p span {
  color: #DD7B47;
}
.animation .animate .animate-left .animate-bottom {
  bottom: 10px;
  position: absolute;
}
.animation .animate .animate-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 3rem 10rem;
  padding-top: 8rem;
}
.animation .animate .letter-shadow {
  display: flex;
  padding-bottom: 1.5rem;
}
.animation .animate .letter-shadow .shadow {
  color: rgba(47, 47, 48, 0.05);
  font-size: 4rem;
  position: absolute;
  margin-top: -2rem;
}

.mob-animation {
  display: none;
}

.animation::-webkit-scrollbar {
  display: none;
}

.institution .office-hero {
  align-items: center;
  justify-content: space-between;
}
.institution .office-hero .office-hero-text {
  padding-block: 3rem;
}
.institution .office-hero .office-hero-img {
  background: linear-gradient(to left, #FEC4B1 50%, transparent 50%);
  padding-block: 10rem;
  width: 50%;
}
.institution .office-hero .office-hero-img img {
  min-height: auto !important;
}
.institution .office-hero .star-text {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-block: 4rem;
  padding-left: 7rem;
  justify-content: center;
  gap: 2rem;
}
.institution .office-hero .office-star-img {
  width: 80%;
  margin-left: -8rem;
  padding-block: 4rem;
  padding-right: 4rem;
}
.institution .office-hero .office-star-img img {
  max-width: 100%;
}

.individual .office-hero {
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}
.individual .office-hero .star-text {
  width: 55%;
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.individual .office-hero .star-text .star-info {
  color: rgba(47, 47, 48, 0.8);
}
.individual .office-hero .office-star-img {
  padding-block: 6rem;
  padding-left: 7rem;
  width: 35%;
  text-align: left;
}
.individual .office-hero .office-star-img img {
  width: 100%;
}

.inflation-page {
  width: 100%;
}

.inflation-guy-hero {
  margin-top: -6rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.inflation-guy-hero h2 {
  color: white;
  padding-bottom: 5rem;
  text-align: center;
  position: absolute;
}
.inflation-guy-hero img {
  position: relative;
  width: 100%;
  height: 100%;
}
.inflation-guy-hero .mob-img {
  display: none;
}
.inflation-guy-hero .desktop-img {
  display: block;
}

.inflation-guy {
  padding-block: 7rem;
}
.inflation-guy .inflation-info {
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: center;
  gap: 2rem;
  margin: auto;
  text-align: center;
}
.inflation-guy .inflation-info p {
  color: rgba(47, 47, 48, 0.8);
}
.inflation-guy .inflation-info .sprinkle1 {
  position: absolute;
  align-self: flex-end;
  margin-right: -4rem;
  margin-top: -3rem;
}
.inflation-guy .inflationMoney {
  position: absolute;
  margin-top: 9rem;
}

.heard {
  background: #2F2F30;
  color: white;
}
.heard .slider .how-carousal {
  animation: scroll 40s linear infinite;
  display: flex;
}
.heard .heard-card {
  min-height: 15vh;
  margin-inline: 5rem;
  display: flex;
  align-items: center;
}
.heard .mob-heard-card {
  display: none;
}

.animationBook {
  position: relative;
  width: 100%;
}
.animationBook .animateBook:nth-child(2) {
  flex-direction: row-reverse;
  gap: 2%;
}
.animationBook .animateBook:nth-child(2) .animateBookLeft {
  width: 35%;
  align-items: flex-start;
}
.animationBook .animateBook:nth-child(2) .animateBookRight {
  align-items: flex-end;
}
.animationBook .animateBook {
  width: 100%;
  padding-block: 5rem;
  display: flex;
  flex-direction: row;
  padding-inline: 10rem;
  scroll-snap-type: x mandatory;
  gap: 7%;
}
.animationBook .animateBook .animateBookLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33%;
  align-items: flex-end;
}
.animationBook .animateBook .animateBookLeft .book-img img {
  width: 100%;
}
.animationBook .animateBook .animateBookRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
}
.animationBook .animateBook .animateBookRight .book-text {
  color: rgba(47, 47, 48, 0.8);
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.mobAnimationBook {
  display: none;
}

.reviews {
  padding: 4rem 7rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.reviews .review-row {
  gap: 6%;
  display: flex;
  width: 100%;
}
.reviews .review-row .review-card {
  width: 43%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 1.25rem;
  box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.05);
}
.reviews .review-row .review-card .star {
  display: flex;
  gap: 0.5rem;
}
.reviews .review-row .review-card p {
  color: rgba(47, 47, 48, 0.8);
  min-height: 6rem;
}
.reviews .ml {
  margin-left: 15%;
}

.podcasts {
  display: flex;
  flex-direction: column;
  padding: 6rem 7rem;
  width: 100%;
  align-items: center;
  background: #2F2F30;
  color: white;
  gap: 4rem;
}
.podcasts .btn-primary {
  border: 1px solid #FFF;
  background: transparent;
}
.podcasts .head {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.podcasts .podcast-body {
  display: flex;
  width: 100%;
  gap: 6%;
}
.podcasts .podcast-body .podcast-img {
  width: 32%;
}
.podcasts .podcast-body .podcast-img img {
  width: 100%;
}
.podcasts .podcast-body .podcast-text {
  display: flex;
  flex-direction: column;
  width: 62%;
}
.podcasts .podcast-body .podcast-text .button-bottom {
  display: none;
}
.podcasts .podcast-body .podcast-text .podcast-row {
  display: flex;
  gap: 1rem;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.podcasts .podcast-body .podcast-text .podcast-row .podcast-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.podcasts .podcast-body .podcast-text .podcast-row:not(:first-child) {
  padding-top: 1.5rem;
}

.photos {
  padding: 6rem 7rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}
.photos img {
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
}
.photos .photo-row {
  display: flex;
  gap: 4%;
  width: 100%;
  margin: auto;
  justify-content: space-between;
}
.photos .photo-row .p1 {
  width: 58%;
}
.photos .photo-row .p2 {
  width: 38%;
}
.photos .photo-row .p3 {
  width: 30%;
}
.photos .photo-row .p4 {
  width: 30%;
}
.photos .photo-row .p5 {
  width: 30%;
}
.photos .photo-row .p6 {
  width: 100%;
}

.mob-photos {
  display: none;
}

.spotify {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FACC71 100%);
  display: flex;
  padding: 6rem 7rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.spotify .spotify-img img {
  width: 100%;
}
.spotify .spotify-info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.spotify .spotify-info p {
  color: rgba(47, 47, 48, 0.8);
}
.spotify .spotify-info .spotify-icon {
  display: flex;
  gap: 6%;
}
.spotify .spotify-info .spotify-icon img {
  width: 45%;
}

.contactUs {
  width: 100%;
}
.contactUs .contactUs-title {
  text-align: center;
  padding-block: 8rem;
}
.contactUs .get-in-touch {
  flex-direction: row-reverse;
}
.contactUs .get-in-touch .form-text .form {
  margin: auto;
  margin-right: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #F8F6F1;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}

#root {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
}

.open-nav {
  transform: translateX(0%) !important;
}/*# sourceMappingURL=main.css.map */