.individual{
    .office-hero{
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        .star-text{
            width: 55%;
            padding-right: 5rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .star-info{
                color: rgba(47, 47, 48, 0.80);
            }
        }
        .office-star-img{
            padding-block: 6rem;
            padding-left: 7rem;
            width: 35%;
            text-align: left;
            img{
                width: 100%;
            }
        }
    }
}