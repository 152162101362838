@media screen and (min-width: 67rem) {
  .navigation {
    padding-inline: 7rem !important;

    .nav-ham {
      display: none;
    }

    .mob-nav-logo {
      display: none;
    }
  }

  // .nav-white{
  //   .navigation-wrapper{
  //     .nav-top{
  //       display: none !important;
  //     }
  //     .nav-bottom {
  //       width: 30%;
  //     }
  //   }
  // }

  .navigation-wrapper {
    background-color: transparent;
    transform: translateX(0%) !important;
    position: relative;
    height: auto;
    flex-flow: row;
    align-items: center !important;
    width: 100% !important;
    max-width: unset !important;

    .nav-bottom {
      width: 35%;
      .blog-btn{
        margin-right: 1.5rem;
      }
    }

    #logo {
      width: 25%;
      display: inline;
    }
    .nav-top {
      width: 45%;
      .mob-nav-logo {
        display: none;
      }
      .routes {
        padding-block: 2rem !important;
        flex-flow: row !important;
        justify-content: space-evenly;
        align-items: center;
        font-size: 1.2rem !important;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .navigation-wrapper {
    .nav-top {
      .routes {
        font-size: 1.2rem !important;
      }
    }
  }
  .why {
    grid-template-columns: 3fr 2fr !important;
    .why-text {
      .why-card {
        left: -14vw !important;
      }
    }
  }
  .benefits {
    height: 60vh !important;
    .benefits-img {
      img {
        width: 150% !important;
      }
    }
  }
  .about {
    min-height: 70vh !important;
  }
  .footer {
    .footer-top {
      width: 60% !important;
      .footer-list {
        gap: 3rem !important;
      }
    }
    .footer-bottom {
      width: 60% !important;
    }
    .footer-privacy{
      width: 60% !important;
    }
  }
  .office-hero {
    .office-hero-text {
      padding-inline: 7rem 7rem !important;
    }
  }
  .institution {
    .office-hero {
      .office-star-img {
        margin-left: 0rem !important;
        padding-right: 0rem !important;
      }
    }
  }
  // .book{
  //   max-height: 75vh !important;
  // }
}

@media screen and (max-width: 960px) and (min-width: 630px) {
  .heard {
    .mob-heard-card {
      gap: 2rem !important;
      div {
        img {
          width: 35% !important;
        }
      }
    }
  }
  .inflation-guy-hero {
    h2 {
      padding-bottom: 6rem !important;
      font-size: 2.25rem !important;
    }
    .desktop-img {
      display: none !important;
    }
    .mob-img {
      display: block !important;
    }
  }
  .about .about-card .inflationGuy {
    width: 25% !important;
    margin-top: -27% !important;
  }
  .how .mob-cards .how-card .how-img img {
    width: 40% !important;
  }
}

@media screen and (max-width: 630px) and (min-width: 450px) {
  .about .about-card .inflationGuy {
    margin-top: -40% !important;
    width: 37% !important;
  }
  .how .mob-cards .how-card .how-img img {
    width: 60% !important;
  }
  .inflation-guy-hero {
    h2 {
      padding-bottom: 5rem !important;
      font-size: 1.6rem !important;
    }
    .desktop-img {
      display: none !important;
    }
    .mob-img {
      display: block !important;
    }
  }
}

@media screen and (max-width: 450px) {
  .about .about-card .inflationGuy {
    width: 55% !important;
    margin-top: -60% !important;
  }
  .how .mob-cards .how-card .how-img img {
    width: 65% !important;
  }
  .inflation-guy-hero {
    h2 {
      padding-bottom: 3rem !important;
      font-size: 1.25rem !important;
    }
    .desktop-img {
      display: none !important;
    }
    .mob-img {
      display: block !important;
    }
  }
}

@media screen and (max-width: 1050px) {
  .width35 {
    width: 100% !important;
  }

  .why {
    grid-template-columns: auto !important;
    height: auto !important;
    padding-block: 1rem !important;
    .why-img {
      .sprinkle1 {
        top: 0;
        right: 0;
        left: auto !important;
        width: 15%;
        margin: 0rem !important;
      }
      .why-img1 {
        width: 100% !important;
        border-radius: 0rem !important;
        padding-top: 3rem;
      }
    }
    .why-text {
      display: block !important;
      width: 90% !important;
      top: -70px;
      margin: auto;
      .why-card {
        left: 0 !important;
        .why-title {
          font-size: 1.875rem !important;
        }
        h2 {
          font-size: 1.25rem !important;
        }
        p {
          font-size: 1rem !important;
        }
        .search {
          display: none !important;
        }
      }
      .sprinkle1 {
        left: 0;
      }
    }
  }
  .benefits {
    flex-direction: column;
    padding: 4rem 1rem 2rem 1rem;
    height: auto !important;
    .sprinkle1 {
      display: none;
    }
    .sprinkle2 {
      display: none;
    }
    .sprinkle3 {
      display: none;
    }
    .benefits-img {
      display: none;
    }
    .mob-benefits-img {
      display: block !important;
    }
    .benefits-text {
      width: 100% !important;
      gap: 1rem !important;
      padding-bottom: 2rem;
      h2 {
        font-size: 1.875rem !important;
      }
      p {
        font-size: 1rem !important;
      }
      .btn-primary {
        font-size: 1.25rem !important ;
        svg {
          padding-bottom: 6px !important;
        }
      }
    }
  }
  .how {
    padding-block: 3rem !important;
    .how-title {
      font-size: 1.875rem !important;
      padding-bottom: 2rem !important;
    }
    .slider {
      display: none;
    }
    .mob-cards {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      padding-inline: 1rem;
      gap: 1rem;
      .how-card {
        background: #fff;
        .how-card-title {
          font-size: 1.25rem !important;
        }
      }
    }
  }
  .about {
    padding-inline: 1rem;
    min-height: 70vh !important;
    .about-card {
      width: 100% !important;
      padding: 8rem 1rem 2rem 1rem !important;
      .about-info {
        width: 100% !important;
        .about-title {
          font-size: 1.875rem !important;
        }
        .about-detail {
          font-size: 1rem !important;
        }
        .btn-primary {
          font-size: 1.25rem !important;
        }
      }
      .inflationGuy {
        margin-left: 0 !important;
      }
    }
    .sprinkle1 {
      right: 20px;
      margin-top: -5rem;
      margin-inline-start: 0vw !important;
    }
    .dollar {
      display: none;
    }
  }
  .cloud {
    grid-template-columns: 1fr !important;
    gap: 3rem;
    padding-block: 3rem !important;
    .cloud1 {
      margin-left: -18%;
      align-self: center;
      width: 40% !important;
      left: 0;
    }
    .cloud3 {
      display: none;
    }
    .cloud4 {
      display: none;
    }
    .cloud-text {
      padding-inline: 1rem !important;
      gap: 1rem !important;
      .cloud2 {
        margin-top: 20rem !important;
        width: 35% !important;
        margin-right: -2.5rem;
      }
      .cloud-title {
        font-size: 1.875rem !important;
      }
      .cloud-info {
        font-size: 1rem !important;
      }
      .btn-primary {
        font-size: 1.25rem !important;
      }
    }
    .cloud-img {
      justify-content: center;
      .man {
        width: 80% !important;
      }
      .cld {
        width: 65% !important;
        margin-left: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
  .get-in-touch {
    flex-direction: column !important;
    max-height: none !important;
    .form-img {
      width: 100% !important;
      img {
        height: 100% !important;
      }
    }
    .form-text {
      width: 100% !important;
      padding-inline: 0rem !important;
      .form {
        padding-inline: 1rem !important;
        padding-block: 3rem;
        width: 100% !important;
        .contact-form {
          h3 {
            padding-bottom: 2rem !important;
          }
          .name {
            flex-direction: column;
            padding-bottom: 1.4rem !important;
            gap: 1.4rem !important;
            .name-50 {
              width: 100% !important;
            }
          }
          label {
            padding-bottom: 0.3rem !important;
          }
          .textarea-label {
            padding-top: 1.4rem !important;
          }
          button {
            margin-top: 1.4rem !important;
          }
        }
      }
    }
  }

  .footer {
    padding-block: 3rem !important;
    .footer-top {
      flex-direction: column;
      align-items: start !important;
      width: 90% !important;
      padding-inline: 1rem;
      padding-bottom: 2rem !important;
      gap: 2rem !important;
      .logo {
        width: 17% !important;
      }
      .footer-list {
        flex-direction: column;
        gap: 1rem !important;
      }
    }
    .footer-bottom {
      flex-direction: column;
      align-items: start !important;
      width: 90% !important;
      padding-block: 2rem 0rem !important;
      padding-inline: 1rem;
      gap: 1rem;
      h2 {
        font-size: 1.875rem !important;
      }
      .social-media {
        gap: 1.5rem !important;
      }
    }
    .footer-privacy {
      flex-direction: column;
      align-items: start !important;
      width: 90% !important;
      padding-block: 2rem 0rem !important;
      gap: 1rem;
      padding-inline: 1rem;
      padding-block: 2.5rem 0rem !important;
      .policies {
        gap: 0.4rem !important;
      }
    }
  }

  .office-hero {
    flex-direction: column !important;
    padding-block: 3rem 0rem;
    min-height: auto !important;
    gap: 2rem;
    .office-hero-text {
      width: 100% !important;
      padding-inline: 1rem !important;
      gap: 1rem !important;
      .hero-title {
        font-size: 2.5rem !important;
      }
      .hero-subtitle {
        font-size: 1.25rem !important;
      }
      .hero-info {
        padding-right: 0rem !important;
        font-size: 1rem !important;
      }
      .btn {
        padding-top: 0rem !important;
        .btn-primary {
          font-size: 1.25rem !important;
          padding: 0.5rem 2rem !important ;
          svg {
            width: 15%;
          }
        }
      }
      .star-title {
        font-size: 1.875rem !important;
        padding-bottom: 1rem;
      }
      .star-info {
        font-size: 1rem !important;
        padding-bottom: 1rem;
      }
    }
    .office-hero-img {
      width: 100% !important;
      min-height: auto !important;
      img {
        width: 100% !important;
        min-height: auto !important;
      }
    }
    .office-star-img {
      text-align: center;
      img {
        width: 50%;
      }
    }
  }

  .pin-spacer {
    display: none !important;
  }

  .mob-animation {
    display: block !important;
    width: 100%;
    .mob-animate {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      .mob-animate-left {
        background-image: url("../assets/images/bgChecks.png");
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-inline: 1rem !important;
        padding-bottom: 1rem;
        padding-top: 3.5rem;
        .mob-animate-top {
          display: flex;
          justify-content: space-between;
          padding-bottom: 1rem;
          .edu {
            width: 20%;
            margin-left: -1rem;
          }
          p {
            span {
              color: #dd7b47;
            }
          }
        }
      }
      .mob-animate-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-inline: 1rem !important;
        padding-top: 3rem !important;
        padding-bottom: 1.5rem !important;
      }
      .letter-shadow {
        display: flex;
        padding-bottom: 1.5rem;
        .shadow {
          color: rgba(47, 47, 48, 0.05);
          position: absolute;
          font-size: 3.125rem !important;
          margin-top: -1.5rem !important;
        }
      }
    }
  }

  .institution {
    .office-hero {
      min-height: auto !important;
      .office-hero-text {
        padding-block: 0rem !important;
      }
      .office-hero-img {
        padding-block: 4rem !important;
        padding-right: 5rem;
        min-height: auto !important;
        background: -webkit-linear-gradient(
          right,
          #fec4b1 50%,
          #f8f6f1 50%
        ) !important;
        img {
          margin-left: 0rem !important;
        }
      }
      .star-text {
        width: auto !important;
        padding-block: 0rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem;
        .star-title {
          font-size: 1.875rem !important;
        }
        .star-info {
          font-size: 1rem !important;
        }
      }
      .office-star-img {
        padding-bottom: 2rem;
        padding-left: 2rem;
        margin-top: -3rem;
        width: 100% !important;
        margin-left: 0rem !important;
        padding-block: 0rem !important;
        padding-right: 0rem !important;
        img {
          width: 100%;
        }
      }
    }
  }

  .individual {
    .office-hero {
      padding-inline: 0rem !important;
      gap: 0rem !important;
      min-height: auto !important;
      .star-text {
        width: 100% !important;
        padding-left: 1rem;
        padding-right: 1rem !important;
        gap: 1rem !important;
        .star-title {
          font-size: 1.875rem !important;
        }
        .star-info {
          font-size: 1rem !important;
        }
      }
      .office-star-img {
        padding-block: 2rem !important;
        padding-inline: 1rem !important;
        width: 100% !important;
      }
    }
  }
  .inflation-page {
    .animationBook {
      display: none;
    }
  }

  

  .inflation-guy {
    padding-block: 3.5rem !important;
    .inflation-info {
      gap: 1.5rem !important;
      width: 91% !important;
      h2 {
        font-size: 1.875rem !important;
      }
      p {
        font-size: 1rem !important;
      }
      h4 {
        font-size: 1.125rem !important;
      }
      .sprinkle1 {
        display: none;
      }
    }
    .inflationMoney {
      display: none;
    }
  }
  .heard {
    .mob-heard-card {
      display: flex !important;
      flex-direction: column;
      div {
        width: 100%;
        display: flex;
        min-height: 15vh;
        padding-inline: 2rem;
        justify-content: center;
        gap: 10%;
        align-items: center;
        img {
          width: 45%;
          height: 100%;
        }
      }
    }
  }

  .reviews {
    padding: 4rem 1rem !important;
    gap: 1rem !important;
    .review-row {
      flex-direction: column !important;
      gap: 1rem !important;
      .review-card {
        width: 100% !important;
        gap: 1rem !important;
        margin-left: none !important;
        h2 {
          font-size: 1.625rem !important;
        }
        p {
          font-size: 1.125rem !important;
          min-height: 4rem !important;
        }
        h5 {
          font-size: 1.25rem !important;
        }
      }
    }
    .ml {
      margin-left: 0% !important;
    }
  }

  .podcasts {
    padding: 4rem 1rem !important;
    gap: 1.5rem !important;
    .head {
      h2 {
        font-size: 1.875rem !important;
      }
      .btn-primary {
        display: none !important;
      }
    }
    .podcast-body {
      flex-direction: column;
      gap: 0rem !important;
      .podcast-img {
        width: 75% !important;
      }
      .podcast-text {
        width: 100% !important;
        .pt {
          padding-top: 1rem !important;
        }
        .podcast-row {
          padding-block: 1rem !important;
          .podcast-info {
            h2 {
              font-size: 1rem !important;
            }
            p {
              font-size: 0.875rem !important;
            }
          }
        }
        .button-bottom {
          padding-top: 1rem;
          display: block !important;
          text-align: center;
        }
      }
    }
  }

  .photos {
    display: none !important;
  }

  .mob-photos {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
    gap: 1.2rem;
    .p1 {
      width: 100%;
      height: 100%;
      border-radius: 1.25rem;
    }
    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      img {
        width: 47%;
        border-radius: 1.25rem;
      }
    }
  }

  .spotify {
    padding: 4rem 1rem !important;
    flex-direction: column;
    gap: 0rem !important;
    .spotify-img {
      img {
        width: 80% !important;
      }
    }
    .spotify-info {
      gap: 1.5rem !important;
      h2 {
        font-size: 1.875rem !important;
      }
      p {
        font-size: 1.25rem !important;
      }
      .spotify-icon {
        flex-direction: column;
        gap: 1rem !important;
        img {
          width: 80% !important;
        }
      }
    }
  }

  .mobAnimationBook {
    display: flex !important;
    flex-direction: column;
    padding: 3rem 1rem;
    .mobAnimateBook {
      padding-top: 4rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .mobAnimateBookLeft {
        .mob-book-img {
          width: 50%;
          img {
            width: 100%;
          }
        }
      }
      .mobAnimateBookRight {
        .mob-book-text {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          p {
            color: rgba(47, 47, 48, 0.8);
          }
        }
      }
    }
  }

  .contactUs {
    .contactUs-title {
      padding-block: 5rem !important;
      h2 {
        font-size: 2.5rem !important;
      }
    }
  }

  .ReactModal__Content {
    width: 100% !important;
    margin: -2.5rem !important;
  }
}

@media screen and (max-width: 1410px) and (min-width: 960px) {
  .animation {
    .panel {
      .animate {
        .animate-left {
          .letter-shadow {
            .font-small {
              font-size: 2.45rem !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .landing {
    .mob-hero {
      display: flex !important;
      width: 70vw !important;
    }
    .border {
      display: none;
    }
    div {
      padding-left: 0rem !important;
      .fill {
        display: none;
      }
      img {
        display: none;
      }
    }
    .btn {
      padding-top: 0rem !important;
      text-align: center;
      .btn-primary {
        font-size: 1.5rem !important;
        gap: 0rem;
        svg {
          padding-bottom: 5px;
        }
      }
    }
  }
}
